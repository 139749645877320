.wx-pay-con {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;

  .all-textcon {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
  }
  .add-text {
    //color: #DA1212;
    font-size: 14px;
    padding-left: 10px;
  }
 
  .bot-key {
    width: 100%;
    height: 370px;
    position: fixed;
    left: 0;
    bottom: 0;
    .bot-key-con {
      width: 100%;
      height: 100%;
      position: relative;
      .open-card {
        width: 100%;
        position: absolute;
        top: 48px;
        left: 0;
      }
      .card-active {
        top: 0;
        animation: open_card 250ms ease;
      }
      .price-show {
        width: 100%;
        height: 51px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        justify-content: space-between;
        background-color: #f6f6f6;
        position: absolute;
        left: 0;
        top: 20%;
        align-items: center;
        font-weight: 500;
        animation: animate 250ms ease;
        span:nth-child(2) > span {
          font-size: 26px;
          font-weight: bold;
        }
      }
      .price-show-loading {
        @extend .price-show;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .jisuan-text {
          margin-left: 10px;
        }
      }
    }
  }
  .input-money {
    width: 100%;
    height: 65px;
    display: flex;
    border-bottom: 1px solid #f4f4f4;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 20px;
    .left-text {
      font-size: 16px;
      color: #000;
    }
    .set-color {
      color: #556aa2;
    }
    .right-text {
      display: flex;
      align-items: flex-end;
      .price {
        height: 30px;
        font-size: 22px;
        // font-weight: bold;
        align-items: flex-end;
      }
      .line {
        width: 2px;
        height: 30px;
        margin: 0 7px 0 10px;
        background-color: #9dc7b8;
        animation: scaleout 1s infinite ease-in-out;
      }
      .yuan {
        font-size: 12px;
        color: #c6c4c5;
        margin-bottom: 3px;
      }
    }
  }
  .store-info {
    width: 100%;
    height: 68px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    .left-con {
      display: flex;
      flex-direction: column;
      .big-name {
        font-size: 18px;
        color: #000;
      }
      .min-name {
        font-size: 13px;
        color: #b5b5b5;
      }
    }
    .right-con {
      width: 42px;
      height: 42px;
      background-color: #3bb272;
      border-radius: 21px;
      padding: 10px;
    }
  }
}

.coupon_item_bottom {
  padding-top: 4px;
  margin-top: 3px;
  border-top: 1px solid #dfdfdf;
}
.coupon_item {
  width: 96%;
  height: auto;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px 10px;
  box-shadow: 0 0 0.1px rgba(0,0,0,.1);
  border: 1px solid #f1f1f1;
  > .coupon_item_main {
    width: 100%;
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    .coupon_item_type {
      width: 70px;
      height: 58px;
      display: flex;
      font-size: 23px;
      font-weight: 560;
      justify-content: center;
      align-items: center;
      background: #f4f4f4;
      border-radius: 12px 0 12px  0;
    }
    .coupon_item_type::after {
      content: '元';
      font-size: 14px;
      margin-left: 3px;
      margin-top: 10px;
    }
    > .coupon_item_center {
      flex: 1;
      margin-left: 5px;
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 6px;
      justify-content: space-between;
      .coupon_item_card {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        > .coupon_item_title {
          font-size: 16px;
          font-weight: 560;
          color: #2a2b2c;
        }
        .coupon-item-stepper {
          .am-stepper {
            width: 120px;
            margin-top: 10px;
            .coupon-down, .coupon-up  {
              width: 20px;
              height: 20px;
            }
          }
        }
        > .coupon_item_exp_time {
          font-size: 14px;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }
}
.bot-button {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  button {
    width: 50%;
    border-radius: 0;
  }
}
.coupon-all {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 5px 10px 43px;
  .no-coupon {
    width: 100%;
    text-align: center;
    height: 90px;
    line-height: 90px;
    font-size: 20px;
  }
}
.choose_used {
  > .init_con {
    width: 350px;
    height: auto;
    display: flex;
    margin: 8px auto;
    flex-direction: column;
    > .init_con_item {
      width: 100%;
      height: 43px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      color: #2B2C36;
      background-color: #fff;
      justify-content: space-between;
      border-bottom: 1px solid #E5E6F6;
      Radio {
        margin-left: 5px;
        z-index: -10;
      }
    }
  }
  > .title_dis {
    display: inline-block;
    font-size: 14px;
    color: #9091A0;
    line-height: 14px;
    margin-left: 16px;
    margin-top: 10px;
  }
}
.dis-title {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  border-bottom: 1px solid #dfdfdf;
}
.con-dis {
  font-size: 14px;
}
.out_btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: fixed;
  left: 0;
  bottom: 0px;
  font-size: 10px;
  color: #f05454;
  text-align: center;
  background-color: #fff;
  z-index: 20;
  border-top: 1px solid #dfdfdf;
}

@keyframes scaleout {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes animate{
  from {
    top : 40%;
  }
  to {
    top : 20%;
  }
}

@keyframes open_card{
  from {
    top : 48px;
  }
  to {
    top : 0;
  }
}

.adm-number-keyboard-key {
  height: 60px;
  border: solid 4px #f5f5f5;
}

.adm-number-keyboard-key:active {
  background: #333333;
}

.adm-number-keyboard-confirm {
  width: 23%;
  .bs-key {
    border: solid 4px #f5f5f5;
  }
  .ok-key {
    height: 185px;
    border: solid 4px #f5f5f5;
    background: #435fd5;
  }
}


.adm-number-keyboard-popup.adm-popup {
  z-index: 300;
}